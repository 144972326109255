import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AppInsightsService } from './app-insights/app-insights.service';
import { NavigationEnd, Router } from '@angular/router';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hj: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hjSettings: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [AppInsightsService]
})
export class AppComponent implements OnInit {
  constructor(
    private readonly appInsightsService: AppInsightsService,
    private readonly router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.appInsightsService.logPageView(event?.url);
      }
    });
  }

  ngOnInit(): void {
    this.setupHotJar();
  }

  setupHotJar(): void {
    window.hj =
      window.hj ||
      function (...args: unknown[]) {
        window.hj.q = window.hj.q || [];
        window.hj.q.push(args);
      };
    window._hjSettings = { hjid: `${environment.hotjarId}`, hjsv: 6 };

    const scr = document.createElement('script');
    scr.async = Boolean(1);
    scr.src =
      'https://static.hotjar.com/c/hotjar-' +
      window._hjSettings.hjid +
      '.js?sv=' +
      window._hjSettings.hjsv;

    document.head.appendChild(scr);
  }
}
