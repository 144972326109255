import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import { MsalGuardConfiguration } from '@azure/msal-angular/msal.guard.config';
import { MsalInterceptorConfiguration } from '@azure/msal-angular';

export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authentication.clientId,
      authority: environment.authentication.authority,
      redirectUri: environment.authentication.redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage
    },
    system: {
      loggerOptions: {
        piiLoggingEnabled: false,
        logLevel: LogLevel.Info
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/',
    authRequest: {
      scopes: [...environment.authentication.scopes]
    }
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map<string, Array<string>>([
      [environment.backendUrl, environment.authentication.scopes]
    ])
  };
}
