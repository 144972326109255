import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';
import { CHATBOT_PAGE, EVENT_PREFIX, PAGE_PREFIX, RA_CHECK_PAGE } from './app-insights.constants';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights | undefined;

  constructor(private authenticationService: AuthenticationService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        disableCookiesUsage: true,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        disableTelemetry: false, // if true -> disable all telemetry
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: false // if true -> add two headers ('Request-Id' and 'Request-Context') to all CORS requests
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  logPageView(uri: string, properties?: { [key: string]: string | number }) {
    const RISK_ASSESSMENT_URI = '/risk-assessment';

    // Handle '/' uri with the default page
    if (uri !== '/' && uri !== RISK_ASSESSMENT_URI) {
      throw new Error(`Invalid pageUri ${uri}`);
    }

    const pageName = uri === RISK_ASSESSMENT_URI ? RA_CHECK_PAGE : CHATBOT_PAGE;

    properties = {
      userId: this.authenticationService.getUserId() ?? 'anonymous',
      sessionId: 'sessionId',
      ...properties
    };

    // Track page view
    this.appInsights?.trackPageView({ name: PAGE_PREFIX + pageName, properties });
  }

  logEvent(name: string, properties?: { [key: string]: string | number }) {
    properties = {
      userId: this.authenticationService.getUserId() ?? 'anonymous',
      ...properties
    };

    // Track event
    this.appInsights?.trackEvent({ name: EVENT_PREFIX + name }, properties);
  }
}
