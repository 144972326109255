import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SecurityModule } from './security/security.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MarkdownComponent, MarkdownModule, MarkdownPipe } from 'ngx-markdown';
import { authInterceptor } from './security/auth-interceptor';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './state/app-state';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  ButtonComponent,
  FieldsetComponent,
  FieldsetLabelDirective,
  FormFieldComponent,
  FormFieldSuffixDirective,
  HeaderAppLogoDirective,
  HeaderAppNameComponent,
  HeaderCenterDirective,
  HeaderComponent,
  HeaderEndDirective,
  HeaderMobileMenuActionComponent,
  HeaderMobileMenuComponent,
  HeaderMobileMenuEndDirective,
  HeaderMobileMenuStartDirective,
  HeaderNavigationComponent,
  HeaderNavigationLinkComponent,
  IconButtonComponent,
  IconComponent,
  RadioComponent
} from '@totalenergiescode/tds-angular';
import { NgOptimizedImage } from '@angular/common';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, NavbarComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SecurityModule,
    NgbModule,
    FormsModule,
    MarkdownPipe,
    MarkdownComponent,
    MarkdownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(appReducer),
    IconComponent,
    ButtonComponent,
    FormFieldComponent,
    FieldsetLabelDirective,
    RadioComponent,
    FieldsetComponent,
    FormFieldSuffixDirective,
    IconButtonComponent,
    HeaderCenterDirective,
    HeaderEndDirective,
    HeaderAppLogoDirective,
    HeaderAppNameComponent,
    HeaderNavigationComponent,
    HeaderComponent,
    HeaderNavigationLinkComponent,
    HeaderMobileMenuStartDirective,
    HeaderMobileMenuEndDirective,
    HeaderMobileMenuComponent,
    HeaderMobileMenuActionComponent,
    NgOptimizedImage

  ],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync()
  ]
})
export class AppModule {}
