import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  IconButtonComponent,
  IconComponent,
  LayerComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonComponent,
    IconButtonComponent,
    IconComponent,
    LayerComponent
  ],
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() buttonText: string = '';
  @Output() action = new EventEmitter<void>();
}
