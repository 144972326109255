import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicInputValue } from '@types';
import {
  FieldErrorDirective,
  FieldHintDirective,
  FormFieldComponent,
  FormFieldItemDirective,
  FormFieldLabelDirective,
  FormFieldSuffixDirective,
  IconButtonComponent,
  IconComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-dynamic-textarea',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormFieldComponent,
    FormFieldItemDirective,
    FormFieldLabelDirective,
    FieldErrorDirective,
    FieldErrorDirective,
    FieldHintDirective,
    FormFieldSuffixDirective,
    IconButtonComponent,
    IconComponent
  ],
  templateUrl: './dynamic-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicTextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicTextareaComponent),
      multi: true
    }
  ]
})
export class DynamicTextareaComponent implements ControlValueAccessor, Validator {
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() errorMessage: string = 'This field is required';
  @Input() required: boolean = false;
  @Input() info: boolean = false;
  @Input() formText: string = '';
  @Input() className: string = 'form-control';
  @Input() classNameContainer: string = 'form-group';
  @Input() disabled: boolean = false;
  @Input() minLength: number = 0;
  @Input() maxLength: number = 0;
  @Input() rows: number = 11;
  @Output() blurEvent = new EventEmitter<Event>();

  value: string = '';
  isTouched: boolean = false;

  constructor() {}

  get showError(): boolean {
    return this.required && this.isTouched && !this.value;
  }

  get showMinLengthError(): boolean {
    return (
      this.name === 'description' &&
      this.required &&
      this.value?.length < this.minLength &&
      this.isTouched
    );
  }

  onChange: (value: DynamicInputValue) => void = () => {};

  onTouch: () => void = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouch();
  }

  registerOnChange(fn: (value: DynamicInputValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (this.required && (!value || value === '')) {
      return { required: true };
    }
    return null;
  }

  updateValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.value = target.value;

    this.onChange(this.value);
    this.onTouch();
  }

  handleBlur(event: Event): void {
    this.isTouched = true;
    this.onTouch();
    this.blurEvent.emit(event);
  }
}
