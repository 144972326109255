import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../security/authentication.service';
import { ChatbotMessage } from '@types';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socket?: Socket;
  private connectedUser = this.authenticationService.connectedUser;

  constructor(private authenticationService: AuthenticationService) {
    this.open()?.then(() => {});
  }

  async open() {
    this.socket = io(`${environment.webSocketServer}`, {
      path: `${environment.webSocketPath}`,
      extraHeaders: {
        'x-apif-apikey': `${environment.ocpApimSubscriptionKey}`
      },
      reconnectionAttempts: 3,
      transports: ['websocket'],
      withCredentials: false
    });
  }

  close() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  askQuestion(message: ChatbotMessage) {
    this.socket?.emit('question', {
      ...message,
      sessionId: this.socket.id,
      userId: this.connectedUser.value?.userId
    });
  }

  handleAnswer(): Observable<ChatbotMessage> {
    return new Observable((observer) => {
      this.socket?.on('answer', (data: ChatbotMessage) => {
        observer.next(data);
      });
    });
  }

  reset() {
    this.close();
    this.open()?.then(() => {});
    this.handleAnswer();
  }
}
