import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { TooltipInfoComponent } from '../../tooltip/tooltip-info/tooltip-info.component';
import { DynamicInputValue } from '@types';
import {
  FieldErrorDirective,
  FormFieldComponent,
  FormFieldItemDirective,
  FormFieldLabelDirective,
  FormFieldSuffixDirective,
  IconComponent,
  TagComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-dynamic-input-badge',
  standalone: true,
  imports: [
    FormsModule,
    NgForOf,
    NgIf,
    TooltipInfoComponent,
    FormFieldComponent,
    FormFieldItemDirective,
    FormFieldLabelDirective,
    FieldErrorDirective,
    TagComponent,
    FormFieldSuffixDirective,
    IconComponent
  ],
  templateUrl: './dynamic-input-badge.component.html',
  styleUrls: ['./dynamic-input-badge.component.scss']
})
export class DynamicInputBadgeComponent implements ControlValueAccessor, Validator {
  @Input() badges: string[] = [];
  @Input() id: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() errorMessage: string = 'This field is required';
  @Input() infoMessage: string = '';
  @Output() badgesChange = new EventEmitter<string[]>();
  value: string = '';
  isTouched: boolean = false;
  showError = false;

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.addBadge();
    }
  }

  onFocusOut(event: FocusEvent): void {
    event.preventDefault();
    this.addBadge();
  }

  addBadge(): void {
    if (this.value !== '') {
      this.badges = [...this.badges, this.value];
      this.badgesChange.emit(this.badges);
      this.value = '';
      this.validateBadge();
    }
    this.checkBadgeOverflow();
  }

  removeBadge(badge: string): void {
    this.badges = this.badges.filter((b) => b !== badge);
    this.badgesChange.emit(this.badges);
    this.validateBadge();
    this.checkBadgeOverflow();
  }

  onChange: (value: DynamicInputValue) => void = () => {};

  onTouch: () => void = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouch();
  }

  registerOnChange(fn: (value: DynamicInputValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (this.required && (!value || value === '')) {
      return { required: true };
    }
    return null;
  }

  updateValue(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.value = target.value;

    this.onChange(this.value);
    this.onTouch();
  }

  handleBlur(): void {
    this.isTouched = true;
    this.onTouch();
    this.validateBadge();
  }

  checkBadgeOverflow(): void {
    const container = document.querySelector('.tags-container');
    const badges = document.querySelectorAll('.tags-container tds-tag');

    let totalBadgeWidth = 0;
    badges.forEach((badge) => {
      totalBadgeWidth += badge.getBoundingClientRect().width;
    });

    if (container) {
      if (totalBadgeWidth > container.getBoundingClientRect().width) {
        container.classList.add('wrap-tags');
      } else {
        container.classList.remove('wrap-tags');
      }
    }
  }

  validateBadge(): void {
    this.checkBadgeOverflow();
    this.showError = this.required && this.badges.length === 0 && this.isTouched;
  }
}
