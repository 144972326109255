import { ActionReducerMap } from '@ngrx/store';
import { ChatbotState, chatbotReducer } from '../components/chatbot/chatbot-state';
import {
  RiskAssessmentCheckState,
  riskAssessmentCheckReducer
} from '../components/risk-assessment-check/risk-assessment-state';
import { UserDataState, userDataReducer } from '../app-insights/user-data.state';

export interface AppState {
  chatbot: ChatbotState;
  riskAssessment: RiskAssessmentCheckState;
  userData: UserDataState;
}

export const appReducer: ActionReducerMap<AppState> = {
  chatbot: chatbotReducer,
  riskAssessment: riskAssessmentCheckReducer,
  userData: userDataReducer
};
