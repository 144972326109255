<tds-form-field [hasError]="showError || showMinLengthError">
  <tds-form-field-label>{{ label }}</tds-form-field-label>
  <textarea
    (blur)="handleBlur($event)"
    (input)="updateValue($event)"
    [disabled]="disabled"
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [rows]="rows"
    [value]="value"
    data-hj-allow
    tds-form-field-item
  ></textarea>
  <tds-form-field-hint>{{ formText }}</tds-form-field-hint>
  <tds-form-field-suffix *ngIf="showError">
    <tds-icon isFilled name="error" size="sm" variant="danger"></tds-icon>
  </tds-form-field-suffix>
  <tds-form-field-error *ngIf="showMinLengthError"
    >Field must be at least 20 characters.
  </tds-form-field-error>
</tds-form-field>
