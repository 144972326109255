import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { ConnectedUser, UserRole } from '@types';
import { EventError } from '@azure/msal-browser/dist/event/EventMessage';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public connectedUser: BehaviorSubject<ConnectedUser | null> =
    new BehaviorSubject<ConnectedUser | null>(null);

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();

    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            const payload = event.payload as AuthenticationResult;
            this._onLogin(payload.account);
            break;
          }

          case EventType.LOGOUT_SUCCESS: {
            this._onLogout();
            break;
          }

          case EventType.LOGIN_FAILURE:
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            this._onLoginFailure(event.error);
            break;
          }

          default: {
            break;
          }
        }
      }
    });
  }

  getUserId(): string | undefined {
    return this.connectedUser?.value?.userId;
  }

  async getAccessToken(): Promise<string | null> {
    let accessToken = null;
    const redirectResponse = await this.msalService.instance.handleRedirectPromise();
    if (redirectResponse !== null) {
      return redirectResponse.accessToken;
    }
    const account = this.msalService.instance.getActiveAccount();
    if (account === null) {
      return null;
    }

    const accessTokenRequest = {
      scopes: [],
      account: account
    };

    await this.msalService.instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        accessToken = accessTokenResponse.accessToken;
      })
      .catch(async (error) => {
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
          await this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
        }
      });
    console.error('accessToken', accessToken);
    return accessToken;
  }

  public logout(logoutRequest?: EndSessionRequest) {
    this.msalService.logout(logoutRequest);
  }

  public isAdmin(): boolean {
    return (
      this.msalService.instance
        .getActiveAccount()
        ?.idTokenClaims?.roles?.includes(UserRole.ADMIN) || false
    );
  }

  private _onLogin(account: AccountInfo | null) {
    if (!account) {
      return;
    }

    this.msalService.instance.setActiveAccount(account);
    this._loadActiveAccount();
  }

  private _onLogout() {
    this.connectedUser.next(null);
  }

  private _onLoginFailure(error: EventError) {
    this.connectedUser.next(null);
    console.error(error);
    this.router.navigate(['/']);
  }

  private _accountInfoToConnectedUser(accountInfo: AccountInfo | null): ConnectedUser | null {
    if (!accountInfo || !accountInfo.idTokenClaims) return null;

    return {
      name: accountInfo.idTokenClaims.name,
      initials: accountInfo.idTokenClaims.name
        ?.split(' ')
        .map((n) => n.charAt(0))
        .join(' '),
      userId: accountInfo.idTokenClaims.oid,
      roles: accountInfo.idTokenClaims.roles
    } as ConnectedUser;
  }

  private _loadActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      return;
    }
    const activeAccount = this._accountInfoToConnectedUser(
      this.msalService.instance.getActiveAccount()
    );
    this.connectedUser.next(activeAccount);
  }

  private _activeFirstAccountFromSessionStorage() {
    const account = this.msalService.instance.getAllAccounts();
    if (account && account.length > 0) {
      this.msalService.instance.setActiveAccount(account[0]);
    }
  }
}
