import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CheckRiskAssessment } from '@types';
import { HttpService } from '../../http-service/http-service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uploadApi = '/v1/risk-assessment/extract';

  constructor(private httpService: HttpService<never>) {}

  uploadFile(file: File): Observable<CheckRiskAssessment> {
    return this.httpService.uploadFile(this.uploadApi, file);
  }
}
