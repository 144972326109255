<header *ngIf="connectedUser?.value" tds-header>
  <div [name]="'Risk Advisor'" class="header-name" routerLink="/" tds-header-app-name>
    <img
      alt="Smart Toy"
      height="40"
      ngSrc="assets/images/svg/logo.svg"
      tds-header-app-logo
      width="40"
    />
  </div>
  <ng-template #navigationLinks>
    <nav aria-label="Navigation menu" tds-header-navigation>
      <a
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="./"
        routerLinkActive
        tds-header-navigation-link
        >Risk Assessment</a
      >
      <a routerLink="/chatbot" routerLinkActive tds-header-navigation-link>Chatbot</a>
      <a
        *ngIf="isAdmin"
        routerLink="/data-source-management"
        routerLinkActive
        tds-header-navigation-link
        >Data Source Management</a
      >
    </nav>
  </ng-template>

  <ng-template #themeToggler>
    <button
      (click)="toggleTheme()"
      *ngIf="this.mode === 'light_mode'"
      label="Toggle Theme"
      size="lg"
      tds-icon-button
    >
      <tds-icon name="dark_mode"></tds-icon>
    </button>
    <button
      (click)="toggleTheme()"
      *ngIf="this.mode === 'dark_mode'"
      label="Toggle Theme"
      size="lg"
      tds-icon-button
    >
      <tds-icon name="light_mode"></tds-icon>
    </button>
  </ng-template>
  <ng-template #languageDropdown>
    <div class="dropdown mx-1">
      <button
        aria-expanded="false"
        class="dropdown-toggle"
        data-bs-toggle="dropdown"
        id="dropdownMenuButton2"
        tds-button
        type="button"
      >
        {{ currentLang | uppercase }}
      </button>
      <ul aria-labelledby="dropdownMenuButton2" class="dropdown-menu">
        <li
          (click)="switchLanguage(lang.code)"
          (keydown)="switchLanguage(lang.code)"
          *ngFor="let lang of supportedLanguages"
          class="dropdown-item"
        >
          {{ lang.name }}
        </li>
      </ul>
    </div>
  </ng-template>

  <ng-template #profileDropdown>
    <div class="dropdown d-flex justify-content-center align-items-center">
      <button
        aria-expanded="false"
        class="mx-2"
        data-bs-toggle="dropdown"
        id="profile"
        label="Profile"
        tds-icon-button
      >
        <tds-icon name="account_circle" size="md"></tds-icon>
      </button>
      <ul aria-labelledby="profile" class="dropdown-menu dropdown-menu-end">
        <li class="text-uppercase fw-bold user-name" data-hj-suppress>
          {{ (connectedUser | async)?.name }}
        </li>
        <li>
          <hr />
        </li>
        <li>
          <div
            (click)="logout()"
            (keydown)="logout()"
            class="dropdown-item d-flex align-items-center"
          >
            <tds-icon name="logout" size="md"></tds-icon>
            <span class="ms-2">Logout</span>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>

  <tds-header-center>
    <ng-template *ngTemplateOutlet="navigationLinks"></ng-template>
  </tds-header-center>

  <tds-header-end>
    <ng-template *ngTemplateOutlet="themeToggler"></ng-template>
    <ng-template *ngTemplateOutlet="languageDropdown"></ng-template>
    <ng-template *ngTemplateOutlet="profileDropdown"></ng-template>
  </tds-header-end>

  <!-- Menu mobile -->
  <tds-header-mobile-menu>
    <tds-header-mobile-menu-start>
      <ng-template *ngTemplateOutlet="navigationLinks"></ng-template>
    </tds-header-mobile-menu-start>

    <tds-header-mobile-menu-end>
      <div class="d-inline-flex justify-content-center align-items-center my-2">
        <ng-template *ngTemplateOutlet="themeToggler"></ng-template>
        <ng-template *ngTemplateOutlet="languageDropdown"></ng-template>
        <ng-template *ngTemplateOutlet="profileDropdown"></ng-template>
      </div>
    </tds-header-mobile-menu-end>
  </tds-header-mobile-menu>
</header>
