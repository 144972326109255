import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../security/authentication.service';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights | undefined;

  constructor(private authenticationService: AuthenticationService) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        enableCorsCorrelation: true,
        extensions: [angularPlugin]
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(uri: string) {
    this.appInsights?.trackPageView({
      uri: uri,
      iKey: this.authenticationService.getUserId()
    });
  }

  logEvent(name: string, properties?: { [key: string]: string | number }) {
    const userId = this.authenticationService.getUserId();
    this.appInsights?.trackEvent({ name: name }, { userId, ...properties });
  }
}
