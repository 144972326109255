import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

type Id = number | string;

@Injectable({
  providedIn: 'root'
})
export class HttpService<T> {
  baseUrl = `${environment.backend_url}`;

  constructor(private http: HttpClient) {}

  get(id: Id, resource: string): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}${resource}/${id}`);
  }

  post<V>(item: T, resource: string): Observable<V> {
    return this.http.post<V>(`${this.baseUrl}${resource}`, item);
  }

  uploadFile(url: string, file: File): Observable<T> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data'
    });
    return this.http
      .post<T>(`${this.baseUrl}${url}`, formData, { headers: headers, observe: 'response' })
      .pipe(map((response) => response.body as T));
  }
}
