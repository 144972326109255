import { EnvironmentInterface } from './environment-interface';

export const environment: EnvironmentInterface = {
  authentication: {
    clientId: 'ea235e0d-77ed-4e80-a5b1-5058414e63ee',
    authority: 'https://login.microsoftonline.com/329e91b0-e21f-48fb-a071-456717ecc28e',
    scopes: ['api://6c257350-e50c-45a3-9e99-46061be85ed1/User.Read'],
    redirectUri: '/'
  },
  backend_url: 'https://dev.apixnp.iasp.tgscloud.net/riskadvisorapi/v0.0.1',
  webSocketServer: 'https://azwbd-riskadvisor-01.azurewebsites.net',
  webSocketPath: '/chatbot',
  ocpApimSubscriptionKey: 'ALPEWQqUtXF1SGqvpu9n5vWQ5d32PhDs4Qq1IEGiqx9USfs4',
  hotjarId: 5095034,
  appInsightsInstrumentationKey: '54b2a439-b869-417c-96ac-578f41e53515'
};
