<div class="chat-container">
  <div *ngIf="messages.length > 1" class="d-flex justify-content-end w-100">
    <button
      (click)="showConfirmationModal()"
      data-bs-target="#confirmation-modal"
      data-bs-toggle="modal"
      size="md"
      tds-button
      variant="secondary"
    >
      {{ 'CHATBOT.NEW_CONVERSATION' | translate }}
    </button>
    <app-confirmation-modal
      (action)="confirmationModalContent.action()"
      [buttonText]="confirmationModalContent.buttonText"
      [message]="confirmationModalContent.message"
      [title]="confirmationModalContent.title"
    ></app-confirmation-modal>
  </div>
  <div
    *ngIf="messages.length === 0"
    class="d-flex flex-column align-items-center justify-content-center w-100 mt-5"
  >
    <img [ngSrc]="getSmartToyIconSrc()" alt="Smart Toy" height="65" width="65" />
    <div
      class="d-flex align-items-center justify-content-center gap-3 m-4 w-messages mt-3 fs-6 row"
    >
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_1')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_1')"
        [isClickable]="true"
        class="d-flex flex-column p-3 col-md-3 col-sm-7 h-question"
        tds-layer
      >
        <tds-icon name="description" size="sm" variant="info" />
        <span class="mt-1" tds-typography variant="body-2">{{
          'CHATBOT.QUESTION_PROMPT_1' | translate
        }}</span>
      </div>
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_2')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_2')"
        [isClickable]="true"
        class="d-flex flex-column p-3 col-md-3 col-sm-7 h-question"
        tds-layer
      >
        <tds-icon name="gavel" size="sm" variant="green" />
        <span class="mt-1" tds-typography variant="body-2">{{
          'CHATBOT.QUESTION_PROMPT_2' | translate
        }}</span>
      </div>
      <div
        (click)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_3')"
        (keydown)="askQuickQuestion('CHATBOT.QUESTION_PROMPT_3')"
        [isClickable]="true"
        class="d-flex flex-column p-3 col-md-3 col-sm-7 h-question"
        tds-layer
      >
        <tds-icon name="star" size="sm" variant="yellow" />
        <span class="mt-1" tds-typography variant="body-2">{{
          'CHATBOT.QUESTION_PROMPT_3' | translate
        }}</span>
      </div>
    </div>
  </div>
  <div
    #messagesContainer
    class="messages-container d-flex flex-column-reverse overflow-auto w-messages h-75"
  >
    <div *ngIf="robotThinking || displayedMessage" class="d-inline-flex">
      <div class="robot-icon">
        <img [ngSrc]="getSmartToyIconSrc()" alt="Smart Toy" height="25" width="25" />
      </div>
      <div *ngIf="robotThinking" class="d-flex flex-column message px-4 my-1 received">
        <div class="d-inline-flex mt-2 mb-2">
          <div class="loader-dot bg-dark rounded-circle mt-1 mb-1 p-1"></div>
          <div class="loader-dot bg-dark rounded-circle m-1 p-1"></div>
          <div class="loader-dot bg-dark rounded-circle mt-1 mb-1 p-1"></div>
        </div>
      </div>
      <div *ngIf="displayedMessage" class="message received p-3 my-1">
        <markdown [data]="displayedMessage"></markdown>
      </div>
    </div>

    <div *ngFor="let msg of messages" class="d-flex flex-column">
      <div *ngIf="displayDate(msg)" class="d-flex justify-content-center align-items-center mb-3">
        <hr class="hr-date" />
        <span class="message-date">{{ msg.date }}</span>
        <hr class="hr-date" />
      </div>
      <!-- Message de type QUESTION -->
      <div *ngIf="msg.type === 'QUESTION'" class="message p-3 my-1 sent">
        {{ msg.question }}
      </div>
      <div *ngIf="msg.date" class="d-flex justify-content-end mt-2">
        <span class="message-time">{{ msg.time }}</span>
      </div>
      <!-- Message de type ANSWER -->
      <div *ngIf="msg.type === 'ANSWER'" class="d-inline-flex">
        <div class="robot-icon">
          <img [ngSrc]="getSmartToyIconSrc()" alt="Smart Toy" height="25" width="25" />
        </div>
        <div class="d-flex flex-column">
          <div *ngIf="msg.type === 'ANSWER'" class="d-flex flex-column message p-3 my-1 received">
            <markdown [data]="msg.answer"></markdown>
            <span *ngIf="msg.documents && msg.documents.length > 0" class="mt-2 fw-bold">
              {{ 'CHATBOT.SOURCE_LABEL' | translate }}
            </span>
            <ul *ngIf="msg.documents && msg.documents.length > 0" class="my-2">
              <li *ngFor="let source of msg.documents" class="my-1">
                <a
                  [href]="source.url"
                  [isExternal]="true"
                  [showVisitedStyle]="false"
                  target="_blank"
                  tds-link
                >
                  {{ source.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="d-inline-flex align-items-center justify-content-end w-75 mt-1">
            <button (click)="copyMessageToClipboard(msg.answer)" label="Copy" tds-icon-button>
              <tds-icon name="content_copy" size="md" variant="secondary" />
            </button>
            <button #likeBotAnswer (click)="likeMessage(msg.answer)" label="Like" tds-icon-button>
              <tds-icon name="thumb_up" size="md" variant="secondary" />
            </button>
            <button
              #dislikeBotAnswer
              (click)="dislikeMessage(msg.answer)"
              label="Dislike"
              tds-icon-button
            >
              <tds-icon name="thumb_down" size="md" variant="secondary" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center w-100 mb-3">
    <button
      (click)="stopGeneration()"
      *ngIf="displayedMessage !== ''"
      class="mx-2"
      size="md"
      tds-button
      variant="secondary"
    >
      {{ 'CHATBOT.STOP_GENERATING' | translate }}
    </button>
  </div>
  <div class="d-flex align-items-center justify-content-center w-100 mb-3"></div>

  <div class="d-inline-flex justify-content-between align-items-center w-messages mb-2">
    <div class="d-flex align-items-center">
      <tds-tag accent="cool-grey">{{ 'CHATBOT.WARNING_GENERATED_MESSAGE' | translate }}</tds-tag>
    </div>
    <fieldset orientation="horizontal" tds-fieldset>
      <tds-fieldset-label>{{ 'CHATBOT.SOURCE' | translate }}</tds-fieldset-label>
      <tds-radio>
        <input
          (change)="changeSource('HSE')"
          [checked]="this.sourceActive === SourceType.HSE"
          name="hse"
          tds-radio-input
          type="radio"
          value="HSE"
        />
        <span tds-radio-label>HSE</span>
      </tds-radio>
      <tds-radio (change)="changeSource('REX')">
        <input
          [checked]="this.sourceActive === SourceType.REX"
          name="rex"
          tds-radio-input
          type="radio"
          value="REX"
        />
        <span tds-radio-label>REX</span>
      </tds-radio>
    </fieldset>
  </div>
  <div class="w-messages">
    <tds-form-field>
      <textarea
        #questionInput
        (input)="autoGrow($event)"
        (keydown.enter)="sendMessage(); $event.preventDefault()"
        [(ngModel)]="messageToSend.question"
        class="w-100"
        data-hj-allow
        placeholder="{{ 'CHATBOT.QUESTION_PLACEHOLDER' | translate }}"
        rows="1"
        style="height: 1.3rem"
        tds-form-field-item
      ></textarea>
      <tds-form-field-suffix>
        <button (click)="sendMessage()" label="Send message" size="lg" tds-icon-button>
          <tds-icon name="send"></tds-icon>
        </button>
      </tds-form-field-suffix>
    </tds-form-field>
  </div>
  <div class="w-messages context-prompt">
    {{ 'CHATBOT.CONTEXT_PROMPT' | translate }}
  </div>
</div>
