<div aria-hidden="true" class="modal fade" id="confirmation-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content" tds-layer>
      <div class="modal-header">
        <div class="modal-title" id="confirmation-modal-Label">
          {{ title }}
        </div>
        <button data-bs-dismiss="modal" label="Close" size="lg" tds-icon-button>
          <tds-icon name="close"></tds-icon>
        </button>
      </div>
      <div class="modal-body">
        {{ message }}
      </div>
      <div class="modal-footer">
        <button
          aria-label="Cancel"
          data-bs-dismiss="modal"
          size="md"
          tds-button
          type="button"
          variant="secondary"
        >
          {{ 'RISK_ASSESSMENT.CANCEL' | translate }}
        </button>
        <button
          (click)="action.emit()"
          aria-label="Primary Action"
          data-bs-dismiss="modal"
          size="md"
          tds-button
          type="button"
          variant="primary"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
