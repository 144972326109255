export interface ConnectedUser {
  name: string;
  initials: string;
  userId: string;
  roles: UserRole[];
}

export enum UserRole {
  USER = 'RISKADVISOR_USER',
  ADMIN = 'RISKADVISOR_ADMIN'
}
