<tds-form-field [hasError]="showError">
  <tds-form-field-label
    >{{ label }}
    <app-tooltip-info
      *ngIf="infoMessage"
      [message]="infoMessage"
      class="mx-1"
      size="xs"
    ></app-tooltip-info>
  </tds-form-field-label>
  <div class="d-flex w-100 px-2" tds-form-field-item>
    <div class="tags-container">
      <tds-tag
        (close)="removeBadge(badge)"
        *ngFor="let badge of badges"
        accent="blue"
        class="flex-shrink-0"
        isClosable
        >{{ badge }}
      </tds-tag>
      <input
        (blur)="handleBlur()"
        (focusout)="onFocusOut($event)"
        (input)="updateValue($event)"
        (keydown)="onKeyDown($event)"
        [disabled]="disabled"
        [id]="id"
        [placeholder]="placeholder"
        [required]="required"
        [value]="value"
        data-hj-allow
        type="text"
      />
    </div>
    <tds-icon
      *ngIf="showError"
      class="mx-2"
      isFilled
      name="error"
      size="sm"
      variant="danger"
    ></tds-icon>
  </div>
</tds-form-field>
