import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { WebSocketService } from './chatbot.service';
import { AnswerReaction, ChatbotMessage, DocumentSource, MessageType, SourceType } from '@types';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app-state';
import { addMessage, messagesStateSelector, resetMessages, updateMessage } from './chatbot-state';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  ButtonComponent,
  FieldsetComponent,
  FieldsetLabelDirective,
  FormFieldComponent,
  FormFieldModule,
  FormFieldSuffixDirective,
  IconButtonComponent,
  IconComponent,
  LinkComponent,
  RadioComponent,
  TagComponent,
  TileComponent,
  TypographyComponent
} from '@totalenergiescode/tds-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { v4 as uuidV4 } from 'uuid';
import { ConfirmationModalComponent } from '../risk-assessment-check/confirmation-modal/confirmation-modal.component';
import { AppInsightsService } from '../../services/app-insights.service';
import {
  CHATBOT_ASK,
  CHATBOT_CHANGE_SOURCE,
  CHATBOT_COPY,
  CHATBOT_DISLIKE,
  CHATBOT_LIKE,
  CHATBOT_NEW_CONVERSATION,
  CHATBOT_OPEN_LINK,
  CHATBOT_STOP_GENERATION
} from '../../services/app-insights.constants';

type messageDate = {
  date: string;
  question: string;
};

@Component({
  selector: 'app-chatbot',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    FormFieldComponent,
    FormFieldSuffixDirective,
    FormFieldModule,
    IconComponent,
    IconButtonComponent,
    MarkdownModule,
    FormsModule,
    FieldsetLabelDirective,
    RadioComponent,
    LinkComponent,
    FieldsetComponent,
    TagComponent,
    TypographyComponent,
    ConfirmationModalComponent,
    NgOptimizedImage,
    TileComponent
  ],
  providers: [WebSocketService, AppInsightsService],
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewInit, OnDestroy {
  messages: ChatbotMessage[] = [];
  messages$: Observable<ChatbotMessage[]>;

  public messageToSend: ChatbotMessage = {
    question: '',
    type: MessageType.QUESTION
  };
  displayedMessage: string = '';
  disableSendMsg = false;
  sourceActive?: SourceType = SourceType.HSE;
  robotThinking = false;
  index = 0;
  messageDates: messageDate[] = [];
  isPaused = false;
  timeoutId: NodeJS.Timeout | undefined;
  ignoreMessage = false;
  currentMessageDocument: DocumentSource[] = [];
  currentQuestionId: string = '';
  confirmationModalContent = {
    title: '',
    message: '',
    buttonText: '',
    action: () => {}
  };
  protected readonly SourceType = SourceType;
  private destroy$ = new Subject<void>();
  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('questionInput') private questionInput!: ElementRef;

  constructor(
    private store: Store<AppState>,
    public chatService: WebSocketService,
    public appInsightsService: AppInsightsService,
    private changeDetectorRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.messages$ = this.store.select(messagesStateSelector);
  }

  ngOnInit(): void {
    this.messages$.pipe(takeUntil(this.destroy$)).subscribe((messages) => {
      this.messages = messages;
    });
    this.handleDisplayMessage();
  }

  handleDisplayMessage() {
    this.chatService
      .handleAnswer()
      .pipe(takeUntil(this.destroy$))
      .subscribe((message: ChatbotMessage) => {
        if (
          !this.ignoreMessage &&
          message.questionId === this.currentQuestionId &&
          !this.isPaused
        ) {
          this.revealMessage(message);
        }
      });
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  askQuickQuestion(value: string) {
    this.messageToSend.question = this.translate.instant(value);
  }

  public sendMessage() {
    if (
      this.messageToSend.question.trim() === '' ||
      this.disableSendMsg ||
      this.sourceActive === undefined
    ) {
      return;
    }
    this.robotThinking = true;
    this.isPaused = false;
    const date = new Date();
    const formattedDate = date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const formattedTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    this.handleDisplayDateMessage(formattedDate);

    this.currentQuestionId = uuidV4();
    this.messageToSend = {
      question: this.messageToSend.question,
      questionId: this.currentQuestionId,
      date: formattedDate,
      type: MessageType.QUESTION,
      source: this.sourceActive,
      time: formattedTime
    };

    this.appInsightsService.logEvent(CHATBOT_ASK, { question: this.messageToSend.question });

    this.chatService.askQuestion(this.messageToSend);
    this.store.dispatch(addMessage({ message: this.messageToSend }));
    this.messageToSend = { question: '', source: undefined, type: MessageType.QUESTION };
    this.scrollToBottom();
    this.questionInput.nativeElement.style.height = '1rem';
    this.questionInput.nativeElement.classList.remove('scroll');

    // Set a timeout for 2 minutes
    this.timeoutId = setTimeout(() => {
      if (this.robotThinking) {
        // If robot is still thinking after 2 minutes, ignore the message
        this.robotThinking = false;
        this.disableSendMsg = false;
        this.displayedMessage = '';
        this.currentMessageDocument = [];
        this.ignoreMessage = true;
      }
    }, 90_000);
  }

  revealMessage(messagePart: ChatbotMessage): void {
    clearTimeout(this.timeoutId);
    this.robotThinking = false;
    this.disableSendMsg = true;

    if (messagePart.answer) {
      this.displayedMessage += messagePart.answer;
      if (messagePart.answer.includes('<EOA>')) {
        this.storeMessage();
      }
    }

    if (messagePart.documents?.length) {
      this.currentMessageDocument = messagePart.documents;
    }
  }

  storeMessage() {
    const answer = {
      question: this.messageToSend.question,
      questionId: this.currentQuestionId,
      answer: this.displayedMessage,
      type: MessageType.ANSWER,
      documents: this.currentMessageDocument
    };
    this.store.dispatch(addMessage({ message: answer }));
    this.displayedMessage = '';
    this.disableSendMsg = false;
    this.isPaused = true;
    this.currentMessageDocument = [];
  }

  stopGeneration(): void {
    this.storeMessage();
    this.appInsightsService.logEvent(CHATBOT_STOP_GENERATION);
  }

  changeSource(source: SourceType) {
    this.sourceActive = source;
    this.appInsightsService.logEvent(CHATBOT_CHANGE_SOURCE, { source });
  }

  showConfirmationModal() {
    this.confirmationModalContent = {
      title: this.translate.instant('CHATBOT.NEW_CONVERSATION_TITLE'),
      message: this.translate.instant('CHATBOT.NEW_CONVERSATION_MESSAGE'),
      buttonText: this.translate.instant('CHATBOT.NEW_CONVERSATION_BUTTON'),
      action: () => {
        this.newConversation();
      }
    };
  }

  newConversation() {
    this.store.dispatch(resetMessages());
    this.chatService.reset();
    this.handleDisplayMessage();

    this.messageToSend = { question: '', type: MessageType.QUESTION };
    this.displayedMessage = '';
    this.disableSendMsg = false;
    this.robotThinking = false;
    this.sourceActive = SourceType.HSE;
    this.isPaused = false;
    this.currentQuestionId = '';
    this.ignoreMessage = false;
    this.currentMessageDocument = [];
    this.messageDates = [];
    this.index = 0;
    this.appInsightsService.logEvent(CHATBOT_NEW_CONVERSATION);
  }

  showNewConversationButton(): boolean {
    return this.messages.filter((message) => message.type === MessageType.ANSWER).length > 0;
  }

  autoGrow(event: Event) {
    const textarea = event.target as HTMLTextAreaElement;
    const currentRows = textarea.value.split('\n').length;

    if (currentRows <= 4) {
      textarea.style.height = `${currentRows}rem`;
    } else {
      textarea.style.height = '4rem';
      textarea.style.overflowY = 'auto'; // Enable scrolling
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  displayDate(msg: ChatbotMessage): boolean {
    if (msg.type === 'ANSWER') return false;
    return this.messageDates.some(
      (date) => date.question === msg.question && date.date === msg.date
    );
  }

  handleDisplayDateMessage(formattedDate: string) {
    if (this.messageDates.length === 0) {
      this.messageDates.push({ date: formattedDate, question: this.messageToSend.question });
      return;
    }
    if (this.messageDates[this.index]?.date !== formattedDate) {
      this.messageDates.push({ date: formattedDate, question: this.messageToSend.question });
      this.index++;
    }
  }

  getSmartToyIconSrc(): string {
    const bodyElement = document.querySelector('body');
    return bodyElement?.classList?.contains('dark')
      ? 'assets/images/svg/smart-toy-dark.svg'
      : 'assets/images/svg/smart-toy.svg';
  }

  openAnswerSource(url: string) {
    this.appInsightsService.logEvent(CHATBOT_OPEN_LINK, { url });
  }

  copyMessageToClipboard(answer?: string) {
    if (!answer) {
      return;
    }

    this.appInsightsService.logEvent(CHATBOT_COPY, { answer });
    navigator.clipboard.writeText(answer.replace('<EOA>', ''))?.then(() => {});
  }

  rateAnswer(message: ChatbotMessage, rating: AnswerReaction) {
    if (!message?.answer) {
      return;
    }

    this.store.dispatch(updateMessage({ message: { ...message, answerReactions: rating } }));

    this.appInsightsService.logEvent(
      rating === AnswerReaction.LIKED ? CHATBOT_LIKE : CHATBOT_DISLIKE,
      { answer: message.answer }
    );
  }

  private scrollToBottom() {
    try {
      this.messagesContainer.nativeElement.scrollTop =
        this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error('Could not scroll to bottom', err);
    }
  }

  protected readonly AnswerReaction = AnswerReaction;
}
