import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RiskAssessmentCheckComponent } from './components/risk-assessment-check/risk-assessment-check.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { DataSourceManagementComponent } from './components/data-source-management/data-source-management.component';
import { AdminGuard } from './security/admin-guard';

const routes: Routes = [
  {
    path: '',
    component: RiskAssessmentCheckComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'chatbot',
    component: ChatbotComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'data-source-management',
    component: DataSourceManagementComponent,
    canActivate: [MsalGuard, AdminGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
