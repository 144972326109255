import { HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const auth = inject(AuthenticationService);
  const ocpApimSubscriptionKey = `${environment.ocpApimSubscriptionKey}`;
  if (req.url.startsWith('../assets/i18n')) {
    return next(req);
  }

  return from(
    auth.getAccessToken().then((token) => {
      if (!token) {
        auth.login();
        return Promise.reject(new Error('No token'));
      }

      let headers = req.headers;
      if (headers === null) {
        headers = new HttpHeaders();
      }
      headers = headers.append('Authorization', `Bearer ${token}`);
      headers = headers.append('x-apif-apikey', ocpApimSubscriptionKey);

      return req.clone({ headers });
    })
  ).pipe(
    switchMap((clonedReq) => next(clonedReq)),
    catchError((error: Error) => {
      return from(Promise.reject(error));
    })
  );
};
