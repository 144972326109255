import { EnvironmentInterface } from './environment-interface';

export const environment: EnvironmentInterface = {
  authentication: {
    clientId: 'ea235e0d-77ed-4e80-a5b1-5058414e63ee',
    authority: 'https://login.microsoftonline.com/329e91b0-e21f-48fb-a071-456717ecc28e',
    scopes: ['api://6c257350-e50c-45a3-9e99-46061be85ed1/User.Read'],
    redirectUri: '/'
  },
  backendUrl: 'https://dev.apixnp.iasp.tgscloud.net/riskadvisorapi/v0.0.1',

  webSocketServerUrl: 'wss://dev.apixnp.iasp.tgscloud.net/riskadvisorws/v0.0.1',
  ocpApimSubscriptionKey: 'tOOj2qeokqwuBVLj5FayOHna1jIvrhvkpKYhZlpXyd2tn1Cx',

  hotjarId: 5095034,
  appInsightsInstrumentationKey: '54b2a439-b869-417c-96ac-578f41e53515'
};
