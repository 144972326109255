import { createAction, createFeatureSelector, createReducer, on, props } from '@ngrx/store';
import UserData from './user-data.type';

export interface UserDataState {
  userData: UserData;
}

export const initialUserDataState: UserDataState = {
  userData: {
    aadUserId: '',
    branch: '',
    site: '',
    job: ''
  }
};

export const setUserData = createAction('setUserData', props<{ userData: UserData }>());
export const resetUserData = createAction('resetUserData');
export const getUserData = createAction('getUserData');

export const userDataReducer = createReducer(
  initialUserDataState,
  on(setUserData, (state, { userData }) => ({ ...state, userData })),
  on(resetUserData, (state) => ({ ...state, userData: initialUserDataState.userData })),
  on(getUserData, (state) => state)
);

export const userDataStateSelector = createFeatureSelector<UserDataState>('userData');
export const userDataSelector = (state: UserDataState) => state.userData;
