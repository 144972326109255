import { Injectable } from '@angular/core';
import { CheckRiskAssessment, RelatedRex } from '@types';
import { Observable } from 'rxjs';
import { HttpService } from '../../http-service/http-service';

@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentRexService {
  riskAssessmentRexApi = '/v1/risk-assessment/rex';

  constructor(private httpService: HttpService<CheckRiskAssessment>) {}

  runRiskAssessmentRex(checkRiskAssessment: CheckRiskAssessment): Observable<RelatedRex[]> {
    return this.httpService.post<RelatedRex[]>(checkRiskAssessment, this.riskAssessmentRexApi);
  }
}
