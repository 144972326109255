import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicInputComponent } from '../shared/Input/dynamic-input/dynamic-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipInfoComponent } from '../shared/tooltip/tooltip-info/tooltip-info.component';
import { RiskAssessmentCheckService } from './risk-assessment-check.service';
import {
  CheckRiskAssessment,
  ControlMeasure,
  CopyStatus,
  GeneratedRiskAssessment,
  Hazard,
  RelatedRex,
  Source
} from '@types';
import { FileUploadService } from './file-upload.service';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DynamicTextareaComponent } from '../shared/Input/dynamic-textarea/dynamic-textarea.component';
import { UploadPdfModalComponent } from './upload-pdf-modal/upload-pdf-modal.component';
import { RiskAssessmentRexService } from './risk-assessment-rex.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app-state';
import {
  resetRiskAssessmentCheckState,
  selectCheckRiskAssessment,
  selectComplementaryHazards,
  selectControlMeasuresSources,
  selectFile,
  selectFileStatus,
  selectHazardsSources,
  selectRelatedRex,
  selectRiskAssessmentCheckRan,
  setCheckRiskAssessment,
  setComplementaryHazards,
  setControlMeasuresSources,
  setFile,
  setFileStatus,
  setHazardsSources,
  setRelatedRex,
  setRiskAssessmentCheckRan,
  updateHazard
} from './risk-assessment-state';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ButtonComponent,
  IconButtonComponent,
  IconComponent,
  IconVariant,
  LinkComponent,
  TagComponent,
  TileComponent,
  TypographyComponent
} from '@totalenergiescode/tds-angular';

import { AppInsightsService } from '../../app-insights/app-insights.service';
import { Router } from '@angular/router';
import {
  COPY_CONTROL_MEASURE,
  COPY_HAZARD,
  FIND_REX,
  IMPORT_PDF,
  IMPORT_PDF_FAILURE,
  IMPORT_PDF_SUCCESS,
  RA_CHECK,
  RA_CHECK_FAILURE,
  RA_CHECK_SUCCESS,
  RA_OPEN_LINK,
  REX_FAILURE,
  REX_OPEN_LINK,
  REX_SUCCESS
} from '../../app-insights/app-insights.constants';

@Component({
  selector: 'app-risk-assessment-check',
  standalone: true,
  imports: [
    CommonModule,
    DynamicInputComponent,
    FormsModule,
    TooltipInfoComponent,
    ConfirmationModalComponent,
    DynamicTextareaComponent,
    UploadPdfModalComponent,
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    IconComponent,
    TagComponent,
    IconButtonComponent,
    LinkComponent,
    TypographyComponent,
    TileComponent
  ],
  templateUrl: './risk-assessment-check.component.html',
  styleUrl: './risk-assessment-check.component.scss',
  providers: [AppInsightsService]
})
export class RiskAssessmentCheckComponent implements AfterViewInit, OnInit, OnDestroy {
  checkRiskAssessment$: Observable<CheckRiskAssessment | null>;
  complementaryHazards$: Observable<Hazard[]>;
  // complementaryControlMeasures$: Observable<ControlMeasure[]>;
  relatedRex$: Observable<RelatedRex[]>;
  riskAssessmentCheckRan$: Observable<boolean>;
  file$: Observable<File>;
  fileStatus$: Observable<string>;
  hazardSource$: Observable<Source[]>;
  controlMeasureSource$: Observable<Source[]>;
  checkRiskAssessmentForm: CheckRiskAssessment = {
    description: '',
    equipmentInvolved: '',
    usedTools: '',
    hazards: [],
    controlMeasures: []
  };
  riskAssessmentCheckRan: boolean = false;
  displaySources: boolean = false;
  complementaryHazards: Hazard[] = [];
  hazardsSources: Source[] = [];
  // complementaryControlMeasures: ControlMeasure[] = [];
  controlMeasuresSources: Source[] = [];
  loading: boolean = false;
  fileName: string | null = null;
  file: File = new File([], '');
  error: string = '';
  uploadLoader: boolean = false;
  titleUploadPdfModal: string = '';
  messageUploadPdfModal: string = '';
  buttonActionTextUploadPdfModal: string = '';
  buttonCancelTextUploadPdfModal: string = '';
  oneFileUploaded: boolean = false;
  errorFileUploaded: boolean = false;
  errorFileUploadedInvalidPdf: boolean = false;
  rexLoader: boolean = false;
  relatedRex: RelatedRex[] = [];
  confirmationModalContent = {
    title: '',
    message: '',
    buttonText: '',
    action: () => {}
  };
  currentPhrase = '';
  intervalId!: ReturnType<typeof setInterval>;
  protected readonly CopyStatus = CopyStatus;
  private readonly destroy$ = new Subject<void>();
  @ViewChild('inputFileRef') private readonly inputFileRef!: ElementRef;

  constructor(
    public riskAssessmentCheckService: RiskAssessmentCheckService,
    public riskAssessmentRexService: RiskAssessmentRexService,
    public fileUploadService: FileUploadService,
    public store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private readonly appInsightsService: AppInsightsService,
    private readonly router: Router
  ) {
    const navigationEntry = performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming;
    if (this.isNavigationExtern() && navigationEntry.type === 'back_forward')
      window.location.reload();
    this.checkRiskAssessment$ = this.store.select(selectCheckRiskAssessment);
    this.complementaryHazards$ = this.store.select(selectComplementaryHazards);
    // this.complementaryControlMeasures$ = this.store.select(selectComplementaryControlMeasures);
    this.relatedRex$ = this.store.select(selectRelatedRex);
    this.riskAssessmentCheckRan$ = this.store.select(selectRiskAssessmentCheckRan);
    this.file$ = this.store.select(selectFile);
    this.fileStatus$ = this.store.select(selectFileStatus);
    this.hazardSource$ = this.store.select(selectHazardsSources);
    this.controlMeasureSource$ = this.store.select(selectControlMeasuresSources);
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      sessionStorage.setItem('navigationExtern', 'false');
    });
    this.updateRiskAssessmentState();
  }

  isNavigationExtern(): boolean {
    return sessionStorage.getItem('navigationExtern') === 'true';
  }

  @HostListener('window:beforeunload')
  unloadHandler() {
    sessionStorage.setItem('navigationExtern', 'true'); // Marque que la navigation est extern
  }

  updateRiskAssessmentState() {
    this.checkRiskAssessment$.pipe(takeUntil(this.destroy$)).subscribe((checkRiskAssessment) => {
      this.checkRiskAssessmentForm = {
        description: checkRiskAssessment?.description ?? '',
        equipmentInvolved: checkRiskAssessment?.equipmentInvolved ?? '',
        usedTools: checkRiskAssessment?.usedTools ?? '',
        hazards: Array.isArray(checkRiskAssessment?.hazards)
          ? [...new Set(checkRiskAssessment?.hazards)].join('\n')
          : (checkRiskAssessment?.hazards ?? ''),
        controlMeasures: Array.isArray(checkRiskAssessment?.controlMeasures)
          ? [...new Set(checkRiskAssessment?.controlMeasures)].join('\n')
          : (checkRiskAssessment?.controlMeasures ?? '')
      };
    });
    this.complementaryHazards$.pipe(takeUntil(this.destroy$)).subscribe((complementaryHazards) => {
      this.complementaryHazards = complementaryHazards;
    });
    this.hazardSource$.pipe(takeUntil(this.destroy$)).subscribe((hazardsSources) => {
      this.hazardsSources = hazardsSources;
    });
    // this.complementaryControlMeasures$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((complementaryControlMeasures) => {
    //     this.complementaryControlMeasures = complementaryControlMeasures;
    //   });
    this.controlMeasureSource$
      .pipe(takeUntil(this.destroy$))
      .subscribe((controlMeasuresSources) => {
        this.controlMeasuresSources = controlMeasuresSources;
      });
    this.relatedRex$.pipe(takeUntil(this.destroy$)).subscribe((relatedRex) => {
      this.relatedRex = relatedRex;
    });
    this.riskAssessmentCheckRan$
      .pipe(takeUntil(this.destroy$))
      .subscribe((riskAssessmentCheckRan) => {
        this.riskAssessmentCheckRan = riskAssessmentCheckRan;
      });
    this.file$.pipe(takeUntil(this.destroy$)).subscribe((file) => {
      this.file = file;
      this.formatFilename(file);
    });
    this.fileStatus$.pipe(takeUntil(this.destroy$)).subscribe((fileStatus) => {
      if (fileStatus === 'error file') {
        this.errorFileUploaded = true;
      }
      if (fileStatus === 'new file') {
        this.oneFileUploaded = true;
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  updateInputsState(event: Event, field: string) {
    const checkRiskAssessment = {
      ...this.checkRiskAssessmentForm,
      [field]: (event.target as HTMLInputElement).value
    };
    this.store.dispatch(setCheckRiskAssessment({ checkRiskAssessment: checkRiskAssessment }));
  }

  runRiskAssessmentCheck() {
    this.appInsightsService.logEvent(RA_CHECK, {
      file: this.file.name
    });

    this.startPhraseRotation();
    this.closeError();
    this.loading = true;
    const checkRiskAssessmentRequest: CheckRiskAssessment = {
      description: this.checkRiskAssessmentForm.description.trim(),
      equipmentInvolved: this.checkRiskAssessmentForm.equipmentInvolved?.trim(),
      usedTools: this.checkRiskAssessmentForm.usedTools?.trim(),
      hazards:
        typeof this.checkRiskAssessmentForm.hazards === 'string'
          ? this.checkRiskAssessmentForm.hazards.split('\n')
          : [],
      controlMeasures:
        typeof this.checkRiskAssessmentForm.controlMeasures === 'string'
          ? this.checkRiskAssessmentForm.controlMeasures.split('\n')
          : []
    };
    this.riskAssessmentCheckService
      .runRiskAssessmentCheck(checkRiskAssessmentRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: GeneratedRiskAssessment) => {
          this.appInsightsService.logEvent(RA_CHECK_SUCCESS, {
            file: this.file.name
          });

          if (
            response.missingHazardsControlMeasures === undefined ||
            response.missingHazardsControlMeasures.length === 0
          ) {
            this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: true }));
            this.loading = false;
            clearInterval(this.intervalId);
            return;
          }
          const complementaryHazards = response.missingHazardsControlMeasures.map((hazard) => {
            return {
              name: hazard.hazard,
              checkedStatus: CopyStatus.NOT_COPIED,
              controlMeasures: hazard.controlMeasures.map((controlMeasure) => {
                return { name: controlMeasure, checkedStatus: CopyStatus.NOT_COPIED };
              })
            };
          });
          // const complementaryControlMeasures = response.missingControlMeasures.map(
          //   (controlMeasure: string) => {
          //     return { name: controlMeasure, checkedStatus: CopyStatus.NOT_COPIED };
          //   }
          // );
          this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: true }));
          this.scrollToComplementaryComponents();
          this.store.dispatch(
            setComplementaryHazards({ complementaryHazards: complementaryHazards })
          );
          // this.store.dispatch(
          //   setComplementaryControlMeasures({
          //     complementaryControlMeasures: complementaryControlMeasures
          //   })
          // );
          this.store.dispatch(
            setHazardsSources({ hazardsSources: response.missingHazardsSources })
          );
          this.store.dispatch(
            setControlMeasuresSources({
              controlMeasuresSources: response.missingControlMeasuresSources
            })
          );
          this.loading = false;
          clearInterval(this.intervalId);
        },
        error: (err) => {
          if (err?.status === 422) {
            this.error = 'The Risk Assessment check is only available in English.';
          } else {
            this.error = err?.error?.message;
          }
          this.loading = false;
          clearInterval(this.intervalId);

          this.appInsightsService.logEvent(RA_CHECK_FAILURE, {
            file: this.file.name
          });
        }
      });
  }

  getRex() {
    this.appInsightsService.logEvent(FIND_REX);
    this.rexLoader = true;
    const checkRiskAssessmentRequest: CheckRiskAssessment = {
      description: this.checkRiskAssessmentForm.description,
      equipmentInvolved: this.checkRiskAssessmentForm.equipmentInvolved,
      usedTools: this.checkRiskAssessmentForm.usedTools,
      hazards:
        typeof this.checkRiskAssessmentForm.hazards === 'string'
          ? this.checkRiskAssessmentForm.hazards.split('\n')
          : [],
      controlMeasures:
        typeof this.checkRiskAssessmentForm.controlMeasures === 'string'
          ? this.checkRiskAssessmentForm.controlMeasures.split('\n')
          : []
    };
    this.riskAssessmentRexService
      .runRiskAssessmentRex(checkRiskAssessmentRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: RelatedRex[]) => {
          this.appInsightsService.logEvent(REX_SUCCESS);
          this.store.dispatch(setRelatedRex({ relatedRex: response }));
        },
        error: (err) => {
          this.appInsightsService.logEvent(REX_FAILURE);
          this.error = err?.error?.message;
        },
        complete: () => {
          this.rexLoader = false;
        }
      });
  }

  openRex(url: string) {
    this.appInsightsService.logEvent(REX_OPEN_LINK, { url: url });
  }

  uploadPdfModalAction: () => void = () => {};

  scrollToComplementaryComponents() {
    setTimeout(() => {
      document
        .getElementById('complementaries')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  }

  editRiskAssessmentCheck() {
    this.store.dispatch(setRiskAssessmentCheckRan({ riskAssessmentCheckRan: false }));
    this.relatedRex = [];
  }

  verifyCheckButtonEnable() {
    return (
      this.checkRiskAssessmentForm.description.trim().length < 20 ||
      this.checkRiskAssessmentForm.hazards.length == 0 ||
      this.checkRiskAssessmentForm.controlMeasures.length === 0
    );
  }

  updateConfirmationModalContent(value: string) {
    if (value === 'edit') {
      this.confirmationModalContent = {
        message: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_MESSAGE'),
        title: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_TITLE'),
        buttonText: this.translate.instant('RISK_ASSESSMENT.EDIT_MODAL_BUTTON_TEXT'),
        action: () => this.editRiskAssessmentCheck()
      };
    } else {
      this.confirmationModalContent = {
        message: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_MESSAGE'),
        title: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_TITLE'),
        buttonText: this.translate.instant('RISK_ASSESSMENT.CLEAR_MODAL_BUTTON_TEXT'),
        action: () => this.clearAllFields()
      };
    }
  }

  hazardCopied(hazard: Hazard) {
    this.appInsightsService.logEvent(COPY_HAZARD, { hazard: hazard.name });

    if (!hazard.checkedStatus) {
      navigator.clipboard.writeText(hazard.name)?.then(() => {});
      this.store.dispatch(updateHazard({ hazard }));
    }
  }

  controlMeasureCopied(controlMeasure: ControlMeasure) {
    this.appInsightsService.logEvent(COPY_CONTROL_MEASURE, {
      'control-measure': controlMeasure.name
    });

    if (!controlMeasure.checkedStatus) {
      navigator.clipboard.writeText(controlMeasure.name)?.then(() => {});
      // this.store.dispatch(updateControlMeasure({ controlMeasure }));
    }
  }

  iconVariant(value: CopyStatus): IconVariant {
    return value == CopyStatus.ACTIVE ? 'success' : 'secondary';
  }

  openRaCheckDoc(url: string) {
    this.appInsightsService.logEvent(RA_OPEN_LINK, { url: url });
  }

  clearAllFields() {
    this.clearInputFile();
    this.store.dispatch(resetRiskAssessmentCheckState());
  }

  preventDefaultFn = (event: Event) => event.preventDefault();

  getUploader() {
    this.closeError();
    const uploader = document.getElementById('upload-div');
    if (!uploader) return;

    if (this.uploadLoader) return;

    if (this.oneFileUploaded) {
      this.store.dispatch(setFileStatus({ fileStatus: 'new file' }));
      this.activateUploader(
        uploader,
        this.translate.instant('RISK_ASSESSMENT.IMPORT_ANOTHER_PDF'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_ANOTHER_PDF_NOTE'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_NEW_PDF'),
        this.translate.instant('RISK_ASSESSMENT.CANCEL'),
        this.openUploader
      );
    } else if (this.errorFileUploaded) {
      this.store.dispatch(setFileStatus({ fileStatus: 'error file' }));
      this.activateUploader(
        uploader,
        this.translate.instant('RISK_ASSESSMENT.INVALID_PDF'),
        this.translate.instant('RISK_ASSESSMENT.INVALID_PDF_NOTE'),
        this.translate.instant('RISK_ASSESSMENT.IMPORT_PDF'),
        this.translate.instant('RISK_ASSESSMENT.FILL_FIELDS_MANUALLY'),
        this.openUploader
      );
      this.clearAllFields();
    } else {
      this.openUploader();
    }
  }

  openUploader() {
    const uploader = document.getElementById('upload-div');
    if (uploader) {
      uploader.removeEventListener('click', this.preventDefaultFn);
    }

    const input = document.getElementById('rac-file-upload');
    if (!input) return;
    input.click();

    if (uploader) {
      uploader?.addEventListener('click', this.preventDefaultFn);
    }
  }

  clearInputFile(event?: Event) {
    if (event) event.stopPropagation();
    this.fileName = null;
    this.errorFileUploaded = false;
    this.errorFileUploadedInvalidPdf = false;
    this.oneFileUploaded = false;
    this.inputFileRef.nativeElement.value = '';
    const uploader = document.getElementById('upload-div');
    if (uploader) {
      uploader.removeAttribute('data-bs-target');
      uploader.removeAttribute('data-bs-toggle');
    }
  }

  activateUploader(
    uploader: HTMLElement,
    title: string,
    message: string,
    buttonActionText: string,
    buttonCancelText: string,
    action: () => void
  ) {
    uploader?.addEventListener('click', this.preventDefaultFn);
    uploader.setAttribute('data-bs-target', '#uploadPDFModal');
    uploader.setAttribute('data-bs-toggle', 'modal');
    uploader.click();
    this.titleUploadPdfModal = title;
    this.messageUploadPdfModal = message;
    this.buttonActionTextUploadPdfModal = buttonActionText;
    this.buttonCancelTextUploadPdfModal = buttonCancelText;
    this.uploadPdfModalAction = action;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.formatFilename(file);
      this.store.dispatch(setFile({ file }));
      this.upload(file);
    }
  }

  upload(file: File): void {
    this.appInsightsService.logEvent(IMPORT_PDF, { file: file.name });
    this.closeError();

    this.uploadLoader = true;
    if (file.name.length > 0) {
      this.fileUploadService
        .uploadFile(file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: CheckRiskAssessment) => {
            this.appInsightsService.logEvent(IMPORT_PDF_SUCCESS, { file: file.name });
            this.store.dispatch(setCheckRiskAssessment({ checkRiskAssessment: response }));
            this.store.dispatch(setFileStatus({ fileStatus: 'new file' }));
            this.uploadLoader = false;
            this.oneFileUploaded = true;
          },
          error: (error) => {
            if (error?.error?.message === 'Invalid PDF') {
              this.appInsightsService.logEvent(IMPORT_PDF_FAILURE, { file: file.name });
              this.errorFileUploadedInvalidPdf = true;
              this.oneFileUploaded = false;
              this.store.dispatch(setFileStatus({ fileStatus: 'error file' }));
            } else {
              this.errorFileUploaded = true;
              this.oneFileUploaded = false;
            }
            this.uploadLoader = false;
            this.getUploader();
          }
        });
    }
  }

  hazardChecked(hazard: Hazard): IconVariant {
    return hazard.checkedStatus == CopyStatus.COPIED || hazard.checkedStatus == CopyStatus.ACTIVE
      ? 'primary'
      : 'danger';
  }

  // controlMeasureChecked(controlMeasure: ControlMeasure): IconVariant {
  //   return controlMeasure.checkedStatus == CopyStatus.COPIED ||
  //     controlMeasure.checkedStatus == CopyStatus.ACTIVE
  //     ? 'primary'
  //     : 'danger';
  // }

  startPhraseRotation() {
    let index = 0;
    this.currentPhrase = this.translate.instant('RISK_ASSESSMENT.LOADING_CHECK_PHRASE_1');
    this.intervalId = setInterval(() => {
      index = (index % 4) + 1;
      this.currentPhrase = this.translate.instant('RISK_ASSESSMENT.LOADING_CHECK_PHRASE_' + index);
    }, 3000);
  }

  closeError() {
    this.error = '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.intervalId);
  }

  private formatFilename(file: File) {
    if (file.name === '') {
      this.fileName = null;
      return;
    }
    if (file.name.length > 20) {
      this.fileName = file.name.substring(0, 20) + '...';
    } else {
      this.fileName = file.name;
    }
  }
}
