import { Injectable } from '@angular/core';
import { HttpService } from '../http-service/http-service';
import { Observable } from 'rxjs';
import UserData from './user-data.type';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  constructor(private readonly httpService: HttpService<UserData>) {}

  userDataApi = '/v1/user-data';
  getUserData(): Observable<UserData> {
    return this.httpService.get<UserData>(this.userDataApi);
  }
}
