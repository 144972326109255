import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CHATBOT_PAGE, EVENT_PREFIX, PAGE_PREFIX, RA_CHECK_PAGE } from './app-insights.constants';
import { UserDataService } from './user-data.service';
import { first, lastValueFrom } from 'rxjs';
import UserData from './user-data.type';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app-state';
import { setUserData, userDataStateSelector } from './user-data.state';
import { AuthenticationService } from '../security/authentication.service';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights | undefined;

  constructor(
    private readonly userDataService: UserDataService,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store<AppState>
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        disableCookiesUsage: true,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: false // if true -> add two headers ('Request-Id' and 'Request-Context') to all CORS requests
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  async logPageView(uri: string) {
    const RISK_ASSESSMENT_URI = '/risk-assessment';

    // Handle '/' uri with the default page
    if (uri !== '/' && uri !== RISK_ASSESSMENT_URI) {
      throw new Error(`Invalid pageUri ${uri}`);
    }

    const pageName = uri === RISK_ASSESSMENT_URI ? RA_CHECK_PAGE : CHATBOT_PAGE;

    const userData = await this.getUserData();
    if (!userData?.userId) {
      return;
    }
    const properties = {
      refUri: uri,
      page: PAGE_PREFIX + pageName,
      ...userData
    };

    // Track page view
    this.appInsights?.trackPageView({ name: PAGE_PREFIX + pageName, properties });
    console.log(`Page ${pageName} viewed with properties:`, properties);
  }

  async logEvent(name: string, properties?: { [key: string]: string | number }) {
    const userData = await this.getUserData();
    if (!userData?.userId) {
      return;
    }

    properties = {
      event: EVENT_PREFIX + name,
      ...(properties ?? {}),
      ...userData
    };

    // Track event
    try {
      this.appInsights?.trackEvent({ name: EVENT_PREFIX + name }, properties);
    } catch (error) {
      console.error('Error while tracking event', error);
    }
    console.log(`Event ${name} logged with properties:`, properties);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  async getUserData() {
    // Get user data from the store
    const userData = (await lastValueFrom(this.store.select(userDataStateSelector).pipe(first())))
      ?.userData;
    const connectedUser = this.authenticationService.connectedUser.value;

    if (userData && connectedUser?.userId === userData.aadUserId) {
      return {
        userSession: this.getUserSession(),
        userId: userData.aadUserId,
        userBranch: userData.branch,
        userJob: userData.job,
        userSite: userData.site
      };
    }

    const user: UserData | undefined = await lastValueFrom(
      this.userDataService.getUserData()
    ).catch(() => {
      return undefined;
    });

    if (!user) {
      return undefined;
    }

    // Save user data in the store
    this.store.dispatch(setUserData({ userData: user }));

    return {
      userId: user.aadUserId,
      userSession: this.getUserSession(),
      userBranch: user.branch,
      userJob: user.job,
      userSite: user.site
    };
  }

  getUserSession() {
    return this.appInsights?.context.sessionManager.automaticSession.id ?? 'UNKNOWN';
  }
}
