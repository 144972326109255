import { Component, Renderer2 } from '@angular/core';
import { AuthenticationService } from '../../security/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { language } from '@types';
import { MaterialSymbol } from 'material-symbols';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  public connectedUser = this.authenticationService.connectedUser;

  mode: MaterialSymbol = (sessionStorage.getItem('mode') as MaterialSymbol) || 'light_mode';

  // List of supported languages
  supportedLanguages = [
    { code: language.EN, name: 'English' },
    { code: language.FR, name: 'French' },
    { code: language.ES, name: 'Spanish' },
    { code: language.NL, name: 'Dutch' },
    { code: language.DE, name: 'German' },
    { code: language.KM, name: 'Kmer' },
    { code: language.AR, name: 'Arabic' }
  ];
  currentLang: string;
  protected readonly language = language;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2
  ) {
    const savedLanguage = localStorage.getItem('language') ?? language.EN;
    this.translateService.use(savedLanguage);
    this.currentLang = savedLanguage;
    this.applyTheme();
  }

  public logout() {
    this.authenticationService.logout();
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
    this.currentLang = language;
  }

  toggleTheme() {
    this.mode = this.mode === 'dark_mode' ? 'light_mode' : 'dark_mode';
    sessionStorage.setItem('mode', this.mode);
    this.applyTheme();
  }

  private applyTheme() {
    if (this.mode === 'dark_mode') {
      this.renderer.addClass(document.body, 'dark');
    } else {
      this.renderer.removeClass(document.body, 'dark');
    }
  }
}
