import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../security/authentication.service';
import { ChatbotMessage } from '@types';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private readonly connectedUser = this.authenticationService.connectedUser;
  socket: WebSocket | undefined;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.connect();
  }

  connect() {
    this.authenticationService.getAccessToken().then((token) => {
      if (!token) {
        console.error("Can't connect to chatbot: no token available");
        return;
      }

      this.socket = new WebSocket(
        `${environment.webSocketServerUrl}?token=${token}&x-apif-apikey=${environment.ocpApimSubscriptionKey}`
      );
    });
  }

  askQuestion(message: ChatbotMessage) {
    this.socket?.send(
      JSON.stringify({
        ...message,
        userId: this.connectedUser.value?.userId
      })
    );
  }

  handleAnswer(): Observable<ChatbotMessage> {
    return new Observable((observer) => {
      if (this.socket) {
        this.socket.onmessage = function incoming(data) {
          const message = JSON.parse(data.data) as ChatbotMessage;
          observer.next(message);
        };
      }
    });
  }

  reset() {
    this.disconnect();
    this.connect();
    this.handleAnswer();
  }

  disconnect() {
    this.socket?.close();
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
