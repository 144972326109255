import { Injectable } from '@angular/core';
import { CheckRiskAssessment, GeneratedRiskAssessment } from '@types';
import { Observable } from 'rxjs';
import { HttpService } from '../../http-service/http-service';

@Injectable({
  providedIn: 'root'
})
export class RiskAssessmentCheckService {
  riskAssessmentApi = '/risk-assessment/check';

  constructor(private httpService: HttpService<CheckRiskAssessment>) {}

  runRiskAssessmentCheck(
    checkRiskAssessment: CheckRiskAssessment
  ): Observable<GeneratedRiskAssessment> {
    return this.httpService.post<GeneratedRiskAssessment>(
      checkRiskAssessment,
      this.riskAssessmentApi
    );
  }
}
